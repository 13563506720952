nav{
  display: flex;
  background-color: #911717;
  justify-content: space-between;
  align-items: center;
  padding: 15px 70px;
  height: 70px;
  box-shadow: 4px 2px 16px 2px rgba(0,0,0,.2);  
}

.logout{
  color:#a02929;
}

.landing-link{
  color: #ccc;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  margin-left: 1rem;
  font-weight: 500;
}
.landing-link:hover{
  color: #fff;
}
.landing-link:active{
  outline: none;
}
/* .logout:hover{
  opacity: 0.98;
  transform: scale(0.98);
} */

nav .logo{
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  color:white;
}

nav .hide{
  display: none;
}

nav ul{
  display: flex;
  align-items: center;
  margin-bottom:0;
  justify-content: center;
  transition: all 0.4s ease-in-out;
}

nav ul li{
  list-style: none;
  margin: auto 10px;
}

nav ul li > *{
  color: white;
  text-decoration: none;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  cursor: pointer;
}

nav ul li >*:hover{
  color:#a02929;
  background-color: white;
}

.burger{
  cursor: pointer;
  display: none; 
}

.burger .line{
  width: 30px;
  height: 3px;
  background-color: white;
  margin:5px;
  transition: all 0.4s ease;
}

.nav-active .line1{
  transform: rotate(-45deg) translateY(5px);
}

.nav-active .line2{
  display: none;
}

.nav-active .line3{
  transform: rotate(45deg) translateY(-6px);
}

.nav-active ul{
  transform: translateX(0);
}


/* @media (max-width: 660px){
  nav ul{
    flex-direction: column;
    width: 100%;
    position: absolute;
    left:0;
    height: calc(100% - 70px);
    padding: 0;
    top:70px;
    background-color: cornflowerblue;
    transform: translateX(-100%);
    z-index: 1000;
  }
  nav ul li{
    margin: 40px;
  }
  .burger{
    display: block;
  }
} */




