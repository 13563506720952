.OptionContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  
}

.OptionContainer-container{
  display:inline-block;
}

.Select-Options{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px auto;
}
.Select-Options > *{
  margin: auto 10px;
}

.BtnToggle{
  background: none;
  border: none;
  color: white;
  background: #a02929;
  padding: 10px;
  transition: all 0.1s ease;
  margin-right:20px;
}
.BtnToggle:hover{
  transform: scale(0.98);
}
.BtnToggle:focus{
  outline: none;
}



.InnerOptContainer{
  border-right: 1px solid #ccc;
  text-align: center;
  transition: all 0.4s ease;
  padding:10px 20px;
}

.InnerOptContainer:hover{
  background-color: #f1f1f1;
}

.InnerOptContainer:last-child{
  border:none;
}

.InnerOptContainer input[type=checkbox]{
  cursor: pointer;
  margin-right: 2px;
  background-color: #a02929;
}

.Hide{
  display:none;
}

.show-tooltip{
  position: relative;
}
.show-tooltip::after{
  content: 'please select semester first';
  position: absolute;
  top:50px;
  font-size: .8rem;
  width: max-content;
  color: rgba(255, 0, 0,.9);
  border: 1px solid red;
  padding:4px;
  opacity: 0;
  transition: all 0.5s ease;
}
.show-tooltip:hover::after{
  opacity: 1;
}

.show-tooltip-subs{
  position: relative;
}
.show-tooltip-subs::after{
  content: 'please select semester and department first';
  position: absolute;
  top:50px;
  font-size: .8rem;
  width: max-content;
  color: rgba(255, 0, 0,.9);
  border: 1px solid red;
  padding:4px;
  opacity: 0;
  transition: all 0.5s ease;
}
.show-tooltip-subs:hover::after{
  opacity: 1;
}

.MuiPopover-paper{
  overflow-x: auto !important;
}
.MuiMenuItem-root{
  overflow: unset !important;
}

@media (max-width: 600px){
  .BtnToggle{
    position: absolute;
    left: 7px;
    top:80px;
  } 
}