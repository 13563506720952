.Login{
  display: flex;
  align-items: center;
  min-height: 90vh;
  justify-content: center;
}

/* .Login > *{
  margin: 15px auto;
}
 */

.LoginHeader{
  font-size: 20px;
  color: #a02929;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.LoginForm{
  box-shadow: 0px 0.5rem #33333311;
  border-left: 11vw solid #911717;
  border-radius: 1rem;
  padding: 3rem 3rem 1rem;
  background-color: #fff;
  flex: 0 0 66.66666666667%;
  max-width: 66.66666666667%;
}
.LoginLogo{
  margin-bottom: 1.5rem;
  flex: 0 0 66.66666666667%;
  max-width: 66.66666666667%;
}

.FormSection{
  margin-bottom: 1.5rem;
  flex: 0 0 66.66666666667%;
  max-width: 66.66666666667%;
}
.email, .password{
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-top: 0.5rem;
}
.email label,
.password label{
  display: inline-block;
  margin-bottom: 0.5rem;
}
.LoginInput{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.4rem;
  transition: all 0.15s ease-in-out;
}

.LoginInput:active,
.LoginInput:focus{
  outline: none;
  border-color:#911717;
  box-shadow: 0px 0px 0px 0.25rem #91171722;
}
.btnCont{
  margin-top: 1.5rem;
}
.LoginBtn{
  padding:0.375rem 3rem;
  color: #fff;
  background-color: #911717;
  border-color: #911717;
  text-align: center;
  font-weight: 400;
  vertical-align: middle;
  border-radius: 0.4rem;
  line-height: 1.5rem;
  transition: all 015s ease-in-out;
}
.LoginBtn:hover,
.LoginBtn:active,
.LoginBtn:focus{
  background-color: #701212;
  color: #fff;
}
.Forget{
  font-size: .86rem;
  margin-top: 3rem;
}
.ForgetInput{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  flex: 0 0 56.33333333%;
  max-width: 56.33333333%;
  border-radius: 0.4rem;
  transition: all 0.15s ease-in-out;
}
.ForgetInput:active,
.ForgetInput:focus{
  outline: none;
  border-color:#911717;
  box-shadow: 0px 0px 0px 0.25rem #91171722;
}

.ForgotbtnCont{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.validationMsg{
  color:red;
}
.invalid{
  border-color: red;
}

@media (max-width: 768px){
 .LoginForm{
      border-left: 3vw solid #911717;
      padding:  1rem;
      flex:  0 0 90%;
      max-width: 90%;
 }
 .LoginLogo{
  flex: 0 0 100%;
  max-width: 100%;
}
.FormSection{
  flex: 0 0 100%;
  max-width: 100%;
}
.ForgetInput{
  flex: 0 0 100%;
  max-width: 100%;
}
}