.all-users{
  margin:30px auto;
  width: 90%;
}

.one-user{
  display: flex;
  justify-content: center;
  /* box-shadow: 2px 4px 6px rgba(0,0,0,.1); */
  border-bottom: 1px solid rgba(204, 204, 204,0.6);  
  width: 90%;
  margin: 20px auto;
  padding: 15px;
  align-items: center;
}

/* .one-user:last-child{
  border: 0;
} */
.one-user > * {
  margin: 0px 50px;
}

.form-container{
  position: fixed;
	z-index: 500;
	background-color: white;
	width: 400px;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 4px 2px 16px 2px rgba(0,0,0,.1);
	padding: 16px;
	left: 50%;
	top: 30%;
  
	box-sizing: border-box;
  transition: all 0.3s ease-out;
  height: 350px;
}
.form-container form{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding:2px 30px;
}

.form-container form button{
  flex-grow: 0;
}