.footer{
  background-color: #fff;
  margin-top: 3rem;
  padding: 1rem;
  box-shadow:0px 0px 0.5rem #33333311;
}
.col-container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 0.25rem;
}
.left-col, .right-col{
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}
.footer-logo{
  width: 100%;
  height: auto;
  margin: 5px 0 0 10px;
  vertical-align: middle;
}
.right-col-para{
  margin-bottom: .5rem;
}
@media(max-width: 768px){
  .col-container{
    flex-direction: column;
  }
  .left-col,.right-col{
    flex:  0 0 100%;
    max-width: 100%;
  }
  .left-col{
    padding-left: 0;
    margin:  10px auto;
  }
}