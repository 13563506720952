.filter-body{
  padding:.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filtered-download{
  width: 75%;
  background-color: #fff;
  margin: 3rem auto 0 auto;
  border-radius: .75rem;
  padding: 1rem;
  box-shadow: 0px 0px 0.5rem #33333322;
}
.first-row{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: .5rem;
}
.common-first{
  display: flex;
  flex-direction: column;
}
.second-row{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0.5rem;
  flex-wrap: wrap;
}
.from-date{
  margin-right: 16px;
  margin-left: 16px;
}
.third-row,
.fourth-row{
  display: flex;
  margin-top: 15px;
  align-items: center;
}
.filter-btn{
  margin: auto 10px;
  background-color: #911717;
  color: white;
  border-radius: 8px;
  border:none;
  padding: 0.5rem .75rem;
  transition: all 0.3s ease-in-out;
  box-shadow: 2px 2px 4px rgba(100, 73, 73, 0.2);
}
.filter-btn:hover{
  background-color: #931717;
  transform: scale(1.02);
}
.filter-btn:active{
  outline: none;
  transform: scale(.98);
}
.filter-btn:focus{
  outline: none;
}
.Export-report .filter-btn{
  margin: 0;
  margin-top: 15px;
  position: relative;
  left: 2%;
  margin-left: 5px;
  margin-right:60px;
}
.exportcsvlink {
  color: #fff;
  text-decoration: none;
}
.exportcsvlink:hover{
  color:#fff;
}
.not-allowed , .not-allowed-mail {
  cursor: not-allowed!important;
  position: relative;
  background-color: gray;
}
.not-allowed::after,.not-allowed-mail::after{
  content: 'No appointments found';
  position: absolute;
  width: 100%;
  background-color: white;
  border: red;
  color:red;
  border-radius: 8px;
  left: 0;
  top:-30px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: auto 7px;
  transition: all 0.2s ease-in-out ;
  cursor:default;
  transform: scale(0);
}

.not-allowed-mail::after{
  content:"No Appointments Found";
}

.dont-allow{
  cursor: not-allowed;
}
.not-allowed:hover::after,.not-allowed-mail:hover::after{
  opacity: 1;
  transform: scale(1);
}
.not-allowed:hover,.not-allowed-mail:hover{
  background-color: gray;
}

.paginator{
  display: flex;
  justify-content: space-between;
  width:95%;
}

.paginator > .rowsperpage{
  display:inline-flex;
}


@media print{
  @page{
    size: tabloid;
  }
  /* .contain-table{
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
    position: relative !important;
    overflow: auto;
    z-index: -1;
  }
  .smaller-table{
    padding-top: 100px;
  }*/
  .extra-large-table{
    margin:0;
    padding: 0;
    margin-top: -150px;
    margin-left: -20px;
  }
  #history-table{
    transform: scale(0.85) !important;
    position: relative !important;
    left: -7%;
  } 
}
