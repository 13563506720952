.main-container{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.header-container{
  margin: 0 auto 60px auto;
  max-width: 92%;
}
.header-inner-container{
  display: flex;
  align-items: center;
  margin-top:3rem;
  flex-direction: column;
}
.first-column{
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
  padding: 0 15px;
  margin-bottom: .5rem;
}
.first-col-img{
  width: 90px;
  height: auto;
  margin-left: 0;
}

.second-column{
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
  padding:0;
  text-align: center;
}
.third-column{
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
  padding-top: 18px;
  text-align: center;
}
.third-col-img{
  width: 95px;
  height: auto;
  position: relative;
  top: 10px;
  left: -10px;
}

.info-container{
  box-shadow: 0px 0px 0.5rem #33333322;
  margin: 3rem auto;
  padding: 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.4rem;
  max-width: 83.33333333%;
}
.about-kjsieit{
  color: #911717;
}
.about-kjsieit-info{
  line-height: 1.7rem;
  word-spacing: 0.23rem;
  text-align: justify;
  font-weight: 400;
  margin-bottom:3rem;
}
.mail-to{
  text-decoration: none;
  color: #911717;
}
.mail-to:hover{
  color: #911717;
}

@media (max-width: 600px){
 .info-container{
  flex: 0 0 100%;
  max-width: 100%;
 } 
 .header-inner-container .first-column{
  max-width: 100%;
  text-align: center;
 }
}