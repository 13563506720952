.landing{
  margin: 0;
  padding: 0;
  min-height: 90vh;
  max-width:90%;
  margin: 0 auto;
  overflow: hidden;
}
.top-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;
}
.landing-logo{
  text-align: center;
  margin: 0;
}
.landing-logo-img{
  width: 140px;
  height: auto;
  margin-bottom: 1.5rem;
}
.college-title{
  text-align: center;
  max-width: 100%;
  min-height: 1px;

}
.college-title-h2{
  font-weight: 700;
  font-size: 1.9rem;
  margin-bottom: .5rem;
  line-height: 1.2;
}
.bottom-container{
  margin-top: 50px;
}
.project-title{
  font-size: 55px;
  color: #911717;
  text-align: center;
  margin: 1.5rem 0;
}
.developed-by{
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: .5rem;
  text-align: center
}