.instructions_heading{
    font-weight: bold;
    margin-top: 20px;
}

.examiner-instructions{
    text-align:left;
    background-color: #ffcccc;
    border: 2px solid #800000;
    box-shadow: #ffcccc;
    margin:5px;
}

.examiner-instructions li {
    color:#800000;
    font-weight: 500;
}

.examiner-instructions > table {
    color:#800000;
    margin: 5px auto;
    /* margin-left:-17px!important; */
}
.examiner-instructions > table > tbody > tr > th {
    border: 1px solid #800000;
    padding: 3px;
    font-size: small;
}

.search-bar{
    display: flex;
    align-items: center;
    margin-top:17px;
  }

.search-bar input:focus::placeholder{
    color: #a02929;
}

.search-input{
    border: 1px solid rgba(0,0,0,.1);
    justify-content: space-between;
    margin-left:11px;
}

.input{
    margin-left:5px;
    width: 400px;
}

