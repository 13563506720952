.form-box{
    z-index: 500;
	background-color: white;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 4px 2px 16px 2px rgba(0,0,0,.1);
	padding: 16px;
	left: 50%;
	top: 15%;
    position: absolute;
	box-sizing: border-box;
    width: 600px;
    max-width: 90%;
  transition: all 0.3s ease-out;
  height:600px;
  overflow-y:auto;
}
.form-box form{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding:2px 30px;
}
.form-box input{
    margin: 5px;
}  
.form-box form button{
flex-grow: 50%;
}
.instructions_heading{
    font-weight: bold;
    margin-top: 20px;
}

.instructions{
    display:table;
    text-align:left;
    background-color: #ffcccc;
    border: 2px solid #800000;
    box-shadow: #ffcccc;
    margin:5px;
}

.instructions li {
    color:#800000;
    font-weight: 500;
}

.instructions > table {
    color:#800000;
    margin: 5px;
    margin-left:-17px!important;
}
.instructions > table > tbody > tr > th {
    border: 1px solid #800000;
    padding: 3px;
    font-size: small;
}
.error{
    color:red;
    width:80%;
    font-size:14.5;
}

.form-box .input{
    margin: auto;
    width: 100%;
}

